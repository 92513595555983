import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { Fragment, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

export enum ParsingErrorCode {
  DATE_PARSING_ERROR = 'DATE_PARSING_ERROR',
  AMOUNT_PARSING_ERROR = 'AMOUNT_PARSING_ERROR',
}

export type ParsingError = {
  code: ParsingErrorCode
  data?: string
}

export interface ParsingErrorModalProps {
  isOpen: boolean
  onClose: () => void
  errors: ParsingError[]
}

export const ParsingErrorModal = ({
  isOpen,
  onClose,
  errors,
}: ParsingErrorModalProps) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(isOpen)

  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto ">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full max-w-xl">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 w-full h-full">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Fehler beim Verarbeiten der Daten
                      </Dialog.Title>

                      <div>
                        <div>
                          <span>
                            Beim Verarbeiten der Daten sind folgende Fehler
                            aufgetreten:
                          </span>
                        </div>

                        <div className="py-4 overflow-y-auto max-h-96">
                          {errors.map((error: ParsingError) => {
                            switch (error.code) {
                              case ParsingErrorCode.DATE_PARSING_ERROR:
                                return (
                                  <div className="bg-red-50 rounded-md px-4 py-4 mb-2">
                                    <span>
                                      {t('ERRORS.DATE_PARSING_ERROR_1')}
                                      <p className="bg-gray-100 inline px-2">
                                        {error.data}
                                      </p>{' '}
                                      {t('ERRORS.DATE_PARSING_ERROR_2')}
                                    </span>
                                  </div>
                                )
                              case ParsingErrorCode.AMOUNT_PARSING_ERROR:
                                return (
                                  <div className="bg-red-50 rounded-md px-4 py-4 mb-2">
                                    <span>
                                      {t('ERRORS.AMOUNT_PARSING_ERROR_1')}
                                      <p className="bg-gray-100 inline px-2">
                                        {error.data}
                                      </p>{' '}
                                      {t('ERRORS.AMOUNT_PARSING_ERROR_2')}
                                    </span>
                                  </div>
                                )
                              default:
                                return (
                                  <div className="bg-red-50 rounded-md px-4 py-4 mb-2">
                                    <span>{t('ERRORS.UNKNOWN_ERROR')}</span>
                                    <br />
                                    <span>{error.data}</span>
                                  </div>
                                )
                            }
                          })}
                        </div>

                        <div>{t('ERRORS.PLEASE_TRY_AGAIN')}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => onClose()}
                  >
                    Daten korrigieren
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
